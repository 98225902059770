<template>
  <section>
    <div class="content-header">
      <h2>Gender identity</h2>
    </div>
    <div class="content-wrapper">
      <p>It’s important to remember that gender is a social construct and is a way to help express and contextualize one’s experience and sense of belonging within their own body. Thus, the labels, titles, and definitions used to underpin these experiences will continue to evolve. Sometimes, this may also mean that how you view and understand your own gender identity may evolve over time. The goal of this section on gender identity is to draw your awareness to the complex ways gender can be experienced and is experienced. We hope that this offers you space to learn, and to develop a practice of equity where you centre a deepened understanding of gender identity, along with the confidence to use gender neutral, or gender inclusive language in your writing, in the classroom, and in your interactions within the many communities to which you belong. </p>
      <div class="accordion">
        <accordion
          headerText="Difference between sex and gender "
          accordionID="collapse01"
        >
          <p><strong>Sex</strong> is the medical classification of people as male, female or intersex, usually assigned at birth based on the appearance of a person’s anatomy or sex organs. Sex assigned at birth does not necessarily match someone’s gender or gender identity. When referring to sex, we usually use the terms Male, Female or X. Sex classification does not usually have as many self-identification categories as gender identification. It’s important to note that when you refer to someone as a male or female, you are referring to their sex, which you may not know, and which may not align with their gender identity. </p>
          <p><strong>Gender</strong> refers to the socially constructed roles, behaviours, expressions and identities of girls, boys, women, men and gender diverse people. It influences how people perceive themselves and each other, how they act and interact, and the distribution of power and resources in society. refers to the socially constructed roles, behaviours, expressions and identities of girls, boys, women, men and gender diverse people. It influences how people perceive themselves and each other, how they act and interact, and the distribution of power and resources in society. </p>
        </accordion>
        <accordion
          headerText="Common terms used when referring to gender identity "
          accordionID="collapse02"
        >
          <p>The 2SLGBTQQIA+ community represents individuals with a range of sexual orientations, romantic orientations, and gender identities who have historically built communities with each other to create safe spaces for sexual, romantic, and gender expression. </p>
          <p class="pb-3">This diverse range of genders is continually adapting in an effort to describe the complexity of each individual’s experience with sexuality and gender. At the same time, many people do not assign or identify themselves with a label. After all, labels, like gender, are a social construct, and individuals are not forced to self-identify within those labels. </p>
          <ul>
            <li class="pb-2"><strong>Two-Spirit</strong> is a modern term used by some Indigenous Peoples across the globe (such as North America, the Philippines and other islands) to distinguish gender spectrum and people who find their identity somewhere along a spectrum of gender identity. The term is mainly used by Indigenous People because English has a poor history with adoption and cultural appropriation and the creators of the term acknowledge this. The term specifically refers to people who have both man-identifying and woman-identifying spirits within them and express these spirits along a continuum that is fluid. </li>
            <li class="pb-2"><strong>Cisgender</strong> applies to someone whose gender matches their “assigned” sex at birth. </li>
            <li class="pb-2"><strong>Transgender</strong> applies to a person whose gender is different from their “assigned” sex at birth. The medical community typically assign gender based on sexual organs, but sex and gender are different, and not mutually exclusive. </li>
            <li class="pb-2"><strong>Agender people</strong>, also called genderless, genderfree, non-gendered, or ungendered people are those who identify as having no gender or being without any gender identity. This category includes a very broad range of identities which do not conform to traditional gender norms. </li>
            <li class="pb-2"><strong>Non-binary</strong>, similar to genderqueer, is a spectrum of gender identities that are not exclusively man-identifying or exclusively woman-identifying—identities that are outside the gender binary of man and woman. </li>
            <li class="pb-2"><strong>Gender variance</strong>, or gender nonconformity, is behaviour or gender expression by an individual that does not match masculine and feminine gender norms. People who exhibit gender variance may be called gender variant, gender non-conforming, gender diverse or gender atypical, and may be transgender, or otherwise variant in their gender expression. Some intersex people may also exhibit gender variance. </li>
            <li class="pb-2"><strong>Genderfluid</strong> applies to a person whose gender identity changes over time or changes at different times. </li>
          </ul>
        </accordion>
        <accordion
          headerText="Pronouns "
          accordionID="collapse03"
        >
          <p>Please know that sharing your pronouns is a personal choice; one that is often shaped by community members’ comfort levels, their sense of safety in the space, and their personal decision to share. When our community members share their pronouns, using those personal pronouns is a way to respect them and create an inclusive environment. </p>
          <p>Just as it can be offensive or even harassing to make up a nickname for someone and call them that nickname against their will, actively choosing to ignore the pronouns shared by a member of our community denies their identity as intersex, transgender, non-binary or gender nonconforming people. </p>
        </accordion>
        <accordion
          headerText="Destabilizing assumptions "
          accordionID="collapse04"
        >
          <p>It’s often the case that people make assumptions about the gender of another person based on the person’s appearance or name. These assumptions aren’t always correct, and the act of assuming may send a potentially harmful message—that people have to look a certain way to demonstrate the gender that they are or are not. At the core of equity practice is the intentional and conscious moving away from making assumptions about another person’s lived experience and identity. </p>
          <p>Instead, when we practice equity, we commit to using inclusive language, like the use of gender-neutral pronouns (they/them/their) or phrases (like “partner”) as a means of building an inclusive community. </p>
        </accordion>
        <accordion
          headerText="Some current prevalent issues in the transgender community "
          accordionID="collapse05"
        >
          <p>According to Statistics Canada, transgender Canadians were more likely to have experienced violence since age 15, and more likely to experience inappropriate behaviours in public, online and at work than cisgender Canadians. In a recent nationwide survey, 74% of transgender youth reported experiencing verbal harassment at school, and 37% reported experiencing physical violence. Transgender individuals in Ontario face unemployment over three times the national rate and many more are underemployed. </p>
          <p>As a result of discrimination and bullying, the transgender community faces high rates of mental health issues. Transgender individuals often seek medical services to make their bodies more congruent with their gender identities, where involvement of mental health professionals is also often necessary. Moreover, many transgender individuals and gender-variant individuals experience stigmatization and discrimination as a result of living in a gendered culture into which they are often not included. They may also experience discrimination, harassment, sometimes lethal violence and denial of basic human rights. Even though we are in the 21st century, there are still many hardships and challenges that transgender individuals have to face every day. </p>
          <p>However, progress is being made, and understanding that we must advocate and be an ally to the community can help this country move toward a better future for transgender Canadians. </p>
        </accordion>
        <accordion
          headerText="Empowering stories from the transgender community "
          accordionID="collapse06"
        >
          <h5>Canada’s first transgender mayor: Julie Lemieux </h5>
          <p>On November 5th, 2017, Julie Lemieux won 48% of the vote to become mayor of Très-Saint-Rédempteur, a municipality in the Montérégie region of Quebec. This marked the first time an openly transgender person was elected as mayor in any municipality across Canada and the first female mayor in the history of Très-Saint-Rédempteur. </p>
          <h5>Canada's first transgender judge: Kael McKenzie </h5>
          <p>Kael McKenzie (born 1971) was appointed to the Provincial Court of Manitoba making him the first transgender person appointed as a judge in Canada. He is a member of the Manitoba Métis Nation and has also served as the Manitoba chair of the Canadian Bar Association, and president of the provincial Rainbow Resource Centre for Manitoba's 2SLGBTQQIA+ community. “I didn’t set out to be a trailblazer or to try to have courage. It just happened that way." - Kael McKenzie </p>
          <h5>The first transgender woman in pro hockey: Jessica Platt </h5>
          <p>Jessica Platt spent her childhood in Brights Grove, Ontario, and attended a Catholic secondary school for which she played hockey. After leaving high school, she began hormone replacement therapy, eventually transitioning her gender and played professionally for the Toronto Furies of the now-defunct Canadian Women's Hockey League team. In 2018, she became the first openly transgender woman in North American professional hockey. </p>
          <h5 class="pt-3">Keith Reynolds, 18, Student, Surrey (UK)</h5>
          <div class="panel">
            <p><em>A lot of people have it in their head that we wake up and decide to be Trans. I want people to know that it’s not a choice. Nothing has happened in my life to make me Trans. I was born Trans. I told my mum when I was about 13. She was shocked and didn’t really understand. Then six months later she told my dad, and he was so angry. I love my dad, but he was a very traditional person. There was a lot of tension. </em></p>
            <p><em>I couldn’t wear men’s clothing, or I couldn’t wear men’s deodorant—it would cause an argument. It started to get better, but then Dad got cancer. He died a week before I turned 16. When he got sick we didn’t talk about it anymore. I thought that once he had recovered, we’d go back to talking about it, but he didn’t recover. After my dad died, I found a book about transgender young people, and I gave it to my mum. She read it and it was a complete change. She says her main thing and also my dad’s main thing is they were worried about how it will affect me in life—will I be able to find a job, will I be able to find a partner. Well, those things have happened: I’ve found a partner, I have no trouble finding jobs. </em></p>
          </div>
        </accordion>
        <accordion
          headerText="Summary"
          accordionID="collapse07"
        >
          <p>Our hope is that these stories help you learn and engage with a whole host of personal and professional experiences transgender individuals have gone through and shed light on the ways in which gender identity can shape community experience. As faculty, instructors and educators, it’s important that you learn more about gender identity to create a safer space for students, enabling them to safely bring their true self to the community. </p>
          <p class="pb-2"><strong>Supports for 2SLGBTQQIA+ folks </strong></p>
          <ul>
            <li class="pb-2"><a
                href="https://itgetsbetter.org/"
                target="_blank"
              >It Gets Better Campaign </a> – Suicide awareness and prevention video campaign for 2SLGBTQQIA+ youth</li>
            <li class="pb-2"><a
                href="https://www.youthline.ca/"
                target="_blank"
              >Lesbian, Gay, Bi & Trans Youthline </a> – Free peer support for youth aged 26+ (1-800-268-9688)</li>
            <li class="pb-2"><a
                href="https://pflagcanada.ca/"
                target="_blank"
              >Parents, Friends of Lesbians and Gays (PFLAG) </a> – General resources for 2SLGBTQQIA+ people and their families</li>
            <li class="pb-2"><a
                href="http://www.egale.ca/"
                target="_blank"
              >Egale Canada </a> – Advocacy and education resources and programming centered around 2SLGBTQQIA+ inclusion </li>
            <li><a
                href="https://www.the519.org/"
                target="_blank"
              >The 519 </a> – Phone counselling by appointment for 2SLGBTQQIA+ people aged 18+ and housing support counselling for youth </li>
          </ul>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
